



































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import { formatDate } from "@/lib/date";
import { prepareMoney } from "@/assets/scripts/utils";
import { DocumentsByContract } from "@/models/contract";
import XDataTable from "../hoc/Table.vue";
import XDropdownWithDatePicker from "../hoc/DropdownWithDatePicker.vue";
import XDropdownWithRadioButtons from "../hoc/DropdownWithRadioButtons.vue";
import ActionDropdown from "../ActionDropdown.vue";
import XButton from "../SimpleButton.vue";

@Component({
  components: {
    XDataTable,
    XDropdownWithDatePicker,
    XDropdownWithRadioButtons,
    ActionDropdown,
    XButton,
  },
  computed: {
    ...mapState({ appEnvironment: "appEnvironment" }),
    ...mapGetters({
      contractId: "contract/id",
      contractInactive: "contract/inactive",
      contractClosed: "contract/closed",
    }),
  },
  filters: {
    formatDate(value: string): string {
      return formatDate(value, "full");
    },
    prepareMoney,
  },
})
class PaymentDocumentTable extends Vue {
  [x: string]: any;

  @Prop({ required: true }) readonly documents!: DocumentsByContract[];
  @Prop({ default: 0 }) amount: number;
  @Prop({ default: "[]" }) docs: string;

  headers = [
    { text: "Документ", value: "type", width: "30%" },
    { text: "Аналитика", value: "analytics", width: "12.5%" },
    { text: "Тема", value: "theme", width: "5%" },
    {
      text: "Сумма с НДС",
      value: "sumWithTax",
      width: "15%",
      align: "end",
    },
    {
      text: "Сумма без НДС",
      value: "sumWithoutTax",
      width: "15%",
      align: "end",
    },
    {
      text: "К оплате",
      value: "sumToPaid",
      width: "15%",
      align: "end",
    },
  ];

  docKeys = [
    ["тип", "номер", "дата"],
    "аналитика",
    "тема",
    "суммасндс",
    "суммабезндс",
    "остаток",
  ];

  humanDocTypeByValue = {
    СФ: "Реализация",
    ПЛАТ: "Входящий платеж",
    ПКО: "Приходный ордер",
  };

  paginationProps = {
    page: 1,
    pageCount: 0,
    itemsPerPage: 100,
  }

  selectedAll = false;
  selectedRows: boolean[] = [];

  checked() {
    this.selectedAll = this.selectedRows.every((state) => state);
  }

  /**
   * Вычисляемое свойство, значением которого является объект. Ключами такого
   * объекта являются значения свойств value объекта headers, а значениями - строки,
   * использующиеся в качестве css-классов td-элементов таблицы, что позволяет
   * определять стили для отдельного столбца. Например, для ячейки, расположенной
   * в столбце с заголовком "к оплате", будет создана следующая строка:
   * "data-table__td data-table__td_header_sum-to-paid" (см. vue-dev-tools).
   */
  public get tdClassesByHeaders() {
    const headerValues = this.headers.map(({ value }) => value);
    const tdClasses: { [headerValue: string]: string } = {};

    return headerValues.reduce((acc, headerValue) => {
      const headerId = headerValue.replace("document", "");
      let counter = 0;

      const headerIdKebabCase = headerId.replace(/[A-Z]/g, (match) => {
        const result = match.toLowerCase();
        return counter++ ? `-${result}` : result;
      });

      return {
        ...acc,
        [headerValue]: `data-table__td data-table__td_header_${headerIdKebabCase}`,
      };
    }, tdClasses);
  }

  public get preparedHeaders() {
    return this.headers.slice(0, this.docKeys.length);
  }

  public get preparedDocuments() {
    const requiredKeys = this.docKeys.slice(0, this.docKeys.length);

    let inDocs =[];
    try {
      inDocs = JSON.parse(this.docs);
    } catch (e) {
      console.error(e);
    }

    this.selectedRows = [];
    return this.documents.map((document) => {
      this.selectedRows.push(inDocs.some(inDoc => inDoc.Счет == document.документ));

      const docValues = requiredKeys.map((keyByTd) => {
        return Array.isArray(keyByTd)
          ? keyByTd.map((key) => document[key])
          : document[keyByTd];
      });

      // Пустая строка, как последний элемент массива позволяет использовать дропдаун
      // в каждой заключительной ячейке строки таблицы.
      return [...docValues, ""];
    });
  }

  public get docsLength(): number {
    return this.documents.length;
  }

  public get selectedRowsSum(): number {
    const { documents, selectedRows } = this;

    return documents.reduce(
      (acc, document, i) => (selectedRows[i] ? acc + document["остаток"] : acc),
      0
    );
  }

  @Emit()
  public propagateDocumentEvt(evtName: string, index: number) {
    const currentIndex = index + ((this.paginationProps.page - 1) * this.paginationProps.itemsPerPage);
    this.$emit(evtName, this.documents[currentIndex]["документ"]);
  }

  @Watch("docsLength")
  public docsLengthChanged() {
    this.selectedRows = Array(this.docsLength).fill(false);
  }

  @Watch("selectedRows")
  changeSelectedRows() {
    this.$emit(
      "update:amount",
      this.selectedRowsSum
    );
    const { documents, selectedRows } = this;
    const docs = documents.filter((document, i) => (selectedRows[i])).map((item) => {
      return {
        'Счет': item['документ'],
        'Сумма': item['остаток'],
      }
    });
    this.$emit(
      "update:docs",
      JSON.stringify(docs)
    );
  }
}

export default PaymentDocumentTable;
