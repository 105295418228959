






















































import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import XForm from "@/components/SimpleForm.vue";
import { paymentForm } from "@/assets/scripts/form-fields/main";
import {validationMixin} from "vuelidate";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import {mapGetters, mapState} from "vuex";

const { values, attrs, validations, errors } = paymentForm;

@Component({
  components: {
    XForm,
  },
  computed: {
    ...errors,
    ...mapState({
      appEnvironment: "appEnvironment"
    }),
    ...mapGetters({
      contractId: "contract/id",
    }),
  },
  mixins: [validationMixin],
  validations,
})
class PaymentForm extends Mixins(AppApiMixin, XForm) {
  [x: string]: any

  @Prop({ default: 0 }) amount: number;
  @Prop({ default: "[]" }) docs: string;

  values = values;
  allFieldAttrs = attrs;

  useSubmitButtonLoading = false;
  submitSuccess = "";
  submitError = "";

  paymentFee = 0;

  async mounted() {
    this.changedAmount();
    this.paymentFee = await this.getPaymentFee();
    this.values = {...this.values, ...this.$attrs, docs: this.docs, amount: this.amount};
  }

  async submitForm() {
    this.useSubmitButtonLoading = true;

    const formData = new FormData;
    for (let key in this.values) {
      formData.append(key, this.values[key]);
    }

    formData.append('contractId', this.contractId);

    try {
      const data = await this.getPaymentURL(formData)

      if(data && data.result && (data.url) != 0) {
        window.location.href = data.url;
      }
    } catch (e) {
      console.log('error', e);
      this.submitError = e.data;
    }
    this.submitSuccess = "";
    this.useSubmitButtonLoading = false;
  }

  @Watch("amount")
  changedAmount() {
    this.values['amount'] = this.amount;
    this.values['total'] = this.values['amount'] + (this.values['amount'] * (+this.paymentFee || 0));
    this.values['allowDataHandling'] = false;
  }

  @Watch("docs")
  changedDocs() {
    this.values['docs'] = this.docs;
  }

  calculateTotal() {
    this.values['total'] = this.values['amount'] + (this.values['amount'] * (+this.paymentFee || 0));
  }

  get sumDisabled() {
    // если есть документы, то редактирование суммы заблокировано
    return this.values['docs'] != "[]";
  }
}

export default PaymentForm;
