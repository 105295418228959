







import {Component, Vue} from "vue-property-decorator";
import {mapGetters} from "vuex";

@Component({
  computed: {
    ...mapGetters({
      contractInactive: "contract/inactive",
      contractClosed: "contract/closed",
    }),
  },
})
class PaymentDisabled extends Vue {
  [x: string]: any
  get textMessage() {
    if (this.contractInactive) {
      return "Договор не активирован. Оплата недоступна";
    }
    if (this.contractClosed) {
      return "Договор закрыт. Оплата недоступна";
    }
    return "Оплата по данному договору недоступна";
  }
}

export default PaymentDisabled;
