




























import {Component, Mixins} from "vue-property-decorator";
import Page from "@/components/for-page-grid/Page.vue";
import PaymentForm from "@/components/for-payments-view/PaymentForm.vue";
import PaymentDisabled from "@/components/for-payments-view/PaymentDisabled.vue";
import PaymentDocumentTable from "@/components/for-payments-view/PaymentDocumentTable.vue";
import PaymentHelpCard from "@/components/for-payments-view/PaymentHelpCard.vue";
import {mapGetters, mapState} from "vuex";
import {paymentForm} from "@/assets/scripts/form-fields/main";
import {DocumentsByContract} from "@/models/contract";
import AppApiMixin from "@/components/mixins/AppApi.vue";
import moment from "moment";

const { values } = paymentForm;

@Component({
  components: { Page, PaymentForm, PaymentHelpCard, PaymentDocumentTable, PaymentDisabled },
  computed: {
    ...mapState({ appEnvironment: "appEnvironment" }),
    ...mapGetters({ email: "user/email" }),
    ...mapGetters('contract', {
      contractId: "id",
      contractInactive: "inactive",
      contractClosed: "closed",
      dateStartContract: "dateStartContract",
    }),
  },
})
class Payments extends Mixins(AppApiMixin) {
  [x: string]: any;
  values = values;

  documentList: DocumentsByContract[] = [];

  amount: number = 0;
  docs: string = "[]";

  mounted() {
    this.values = {...this.values, email: this.userEmail, amount: this.amount};
    this.defaultValues = {...this.values, email: this.userEmail, amount: this.amount};

    this.amount = parseFloat(this.$route.params.amount) || 0;
    this.docs = this.$route.params.docs || "[]";

    this.getDocumentsByContract({
        contractId: this.contractId,
        type: 0,
        noPayment: 1,
        from: this.dateStartContract,
        to: moment().format('DD.MM.YYYY'),
      },
      (documentList: DocumentsByContract[]) => {
        this.documentList = documentList;
      }
    );
  }

  get paymentDisabled() {
    return this.contractInactive || this.contractClosed;
  }

  get userEmail() {
    return this.email;
  }
}

export default Payments;
